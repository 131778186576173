<template>
  <div class="card">
    <Button class="p-button-link" @click="$router.go(-1)">返回</Button>
    <data-table :value="list">
      <template #empty>
        <p style="text-align: center">暂无数据</p>
      </template>
      <Column header="评论人" field="name"></Column>
      <Column header="头像" field="pic">
        <template #body="a">
          <img style="width: 80px;height: 80px" :src="url+a.data.pic">
        </template>
      </Column>
      <Column header="回复" field="content">
        <template #body="a">
          <div v-html="a.data.content" style="width: 100px;height: 50px;overflow: hidden"></div>
        </template>
      </Column>
      <Column header="评论时间" field="time">
        <template #body="a">
          {{ new Date(a.data.time).toLocaleString() }}
        </template>
      </Column>
      <Column header="操作">
        <template #body="a">
          <Button class="p-button-sm p-mr-2" @click="details(a.data)">详情</Button>
          <Button class="p-button-sm p-button-danger" @click="deleteComm(a.data.id)">删除</Button>
        </template>
      </Column>
    </data-table>
    <Paginator @page="changePage" :rows="page.size" :totalRecords="page.total" :rowsPerPageOptions="[5,10,25]"
               currentPageReportTemplate="显示 {first} 到 {last} 条记录，总计 {totalRecords} 记录"
               template="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"></Paginator>
  </div>
  <Dialog header="回复详情" v-model:visible="display">
    <div v-html="data.content"></div>
    <p style="width: 100%;text-align: right;margin-top: 10px">{{new Date(data.time).toLocaleString()}}</p>
  </Dialog>
  <Toast/>
</template>

<script>
export default {
  name: "expertArticle",
  data() {
    return {
      display: false,
      data: {},
      url: this.$global_msg.imgurl,
      list: [],
      page: {
        total: 0,
        current: 1,
        size: 10,
      }
    }
  },
  mounted() {
    this.getDate()
  },
  methods: {
    getDate() {
      this.$http.get('/question/getExpertArticle', {
        params: {
          current: this.page.current,
          size: this.page.size,
          questionId: this.$route.query.id
        }
      }).then((res) => {
        this.page.total = res.data.total
        this.list = res.data.records
        console.log(res.data)
      })
    },
    changePage(event) {
      this.page.size = event.rows
      this.page.current = event.page + 1
      this.getData()
    },
    deleteComm(id) {
      this.$http.post('/question/deleteComm/' + id).then(() => {
        this.$toast.add({severity: 'success', summary: '成功', life: 3000});
      })
    },
    details(data) {
      this.display = true
      this.data = data
    }
  }
}
</script>

<style scoped>

</style>